import { HTMLProps, useEffect, useState } from "react";
import { toHumanReadableCurrency } from "../../utils/string.utils";

interface Props extends HTMLProps<HTMLInputElement> {
  label: string;
  containerClassname?: string;
  isCurrency?: boolean;
  isToken?: boolean;
  tokenToPound?: boolean;
  isAutomatic?: boolean;
}

export function FormInput({ label, containerClassname, className, isCurrency, isToken, tokenToPound, isAutomatic, ...props }: Props) {

  const [value, setValue] = useState(props.value || '');

  useEffect(() => {
    setValue(props.defaultValue || '')
  }, [props.defaultValue])

  function getInputClassname() {
    let className = 'w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 '
    className += 'focus:ring-pn-blue focus:border-pn-blue block w-full p-2.5 '

    if (className) {
      className += className;
    }
    return className;
  }

  function getDisplayValue() {
    if (isToken) {
      const count = parseInt(value as string, 10) || 0;
      return `${count} ${count === 1 ? " Token" : " Tokens"}`;
    }
    if (tokenToPound) {
      return toHumanReadableCurrency(parseInt(value as string) * 100 * 100);
    }
    if (isAutomatic === true) {
      return "Automatic"
    }
    if (isAutomatic === false) {
      return "Manual";
    }
    return isCurrency
      ? toHumanReadableCurrency(parseInt(value as string) * 100)
      : "";
  }

  function getId() {
    return props.id || label.toLowerCase().replace(' ', '_');
  }

  return (
    <div className={`flex flex-col mb-4 ${containerClassname}`}>
      <label className="mb-2 text-sm font-semibold" htmlFor={getId()}>{label}</label>
      <div className="relative">
        <p className="absolute text-sm top-3 text-slate-500 right-8">{getDisplayValue()}</p>
        <input className={getInputClassname()} id={getId()} {...props} onChange={(e) => setValue(e.currentTarget.value)} />
      </div>
    </div>
  );
}