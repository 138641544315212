import { useLazyQuery, useMutation } from "@apollo/client";
import { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Property } from "../../__generated__/graphql";
// import { PropertyType } from "../../__generated__/graphql";
import { Form } from "../../components/form/form";
import { Sidebar } from "../../components/sidebar/sidebar";
import { Headline } from "../../components/text/headline";
import { MUTATION_ADD_PROPERTY, MUTATION_UPDATE_PROPERTY } from "../../graphql/mutations/property";
import { QUERY_GET_PROPERTY } from "../../graphql/queries/get-property";
import { AppRoute } from "../../routes";
import { AuthService } from "../../services/auth.service";
import { PropertyFormMessages } from "./components/messages";
import { PropertyFormActions } from "./sections/action-form";
import { PropertyFormAgreementSection } from "./sections/agreement-form";
import { PropertyAmenitiesSection } from "./sections/amenities";
import { PropertyFormAreaSection } from "./sections/area-form";
import { PropertyFinancialSection } from "./sections/financials";
import { PropertyImagesSection } from "./sections/images";
import { PropertyFormProfileSection } from "./sections/profile-form";
import { PropertyRentSection } from "./sections/rent";
import { PropertyFormStatusSection } from "./sections/status-form";
import { PropertyTimelineSection } from "./sections/timeline";
import { LoadingSpinner } from "../../components/loading/spinner";
import { PropertyDocumentsSection } from "./sections/docs";
import { useAuthAndErrorHandling } from "../../utils/invalid-token.util";
import NotificationTopBar from "../../components/notification/notification-bar";
import { PropertyVideosSection } from "./sections/videos";

export function PropertyEditPage() {
  const location = useLocation()
  const navigate = useNavigate();

  const [property, setProperty] = useState<Property>();

  const [getProperty, { data, loading, error }] = useLazyQuery(QUERY_GET_PROPERTY, { errorPolicy: 'all', fetchPolicy: 'no-cache' });
  const [addProperty, { data: submitData, loading: submitting, error: submitError }] = useMutation(MUTATION_ADD_PROPERTY, { errorPolicy: 'all' });
  const [updateProperty, { data: updateData, loading: updating, error: updateError }] = useMutation(MUTATION_UPDATE_PROPERTY, { errorPolicy: 'all' });
  const [mode, setMode] = useState<'add' | 'edit'>('add');
  const [added, setAdded] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [manualFunded, setManualFunded] = useState<number | null>(null);

  useAuthAndErrorHandling(error);

  useEffect(() => {
    if (!AuthService.authenticate) {
      return;
    }
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const _added = queryParams.get('added');
    const _updated = queryParams.get('updated');
    if (id) {
      getProperty({ variables: { id } });
      setMode('edit');
    } else {
      setMode('add');
    }
    if (_added) {
      setAdded(true);
    }
    if (_updated) {
      setUpdated(true);
    }

    // Navigate back to property list when Escape key pressed
    const handleKeyNav = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Escape':
          navigate(AppRoute.PropertyList);
          break;
      }
    };

    window.addEventListener('keydown', handleKeyNav);

    return () => {
      window.removeEventListener('keydown', handleKeyNav);
    };
  }, []);

  useEffect(() => {
    if (submitData && submitData.addProperty && submitData.addProperty.id) {
      navigate(`${AppRoute.Property}?id=${submitData.addProperty.id}&added=1`);
      setProperty(submitData.addProperty);
      setMode('edit');
      setAdded(true);
    }

  }, [submitData]);

  useEffect(() => {
    if (updateData && updateData.updateProperty && updateData.updateProperty.id) {
      navigate(`${AppRoute.PropertyList}?updated=1`);
      setProperty(updateData.updateProperty);
      setUpdated(true);

    }
  }, [updateData]);

  useEffect(() => {
    if (data && data.property) {
      setProperty(data.property);
    }
  }, [data]);

  function refresh() {
    if (!property) {
      return;
    }
    getProperty({ variables: { id: property?.id } });
  }

  function validateForm(formData: FormData): string[] {
    const errors: string[] = [];
    const title = formData.get('title') as string;
    const lat = formData.get('lat') as string;
    const lng = formData.get('lng') as string;
    const description = (formData.get('description') as string) || ''; // Ensure description is a string

    if (title.length < 10 || title.length > 50) {
      errors.push("Property title must be between 10 and 50 characters.");
    }
    if (!lat || !lng || isNaN(parseFloat(lat)) || isNaN(parseFloat(lng))) {
      errors.push("Please select a valid Property Address. The Property Address must be selected from the autocomplete suggestions. This ensures accurate property details.");
    }
    if (description.length < 160) {
      //console.log('Description:', description);
      errors.push("Property description must be at least 160 characters.");
    }

    if (manualFunded !== null && (manualFunded / 100 < 0 || manualFunded / 100 > 100)) {
      errors.push("Funded percentage must be between 0 and 100.");
    }

    return errors;
  }

  function submit(e: FormEvent) {
    e.preventDefault();

    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);

    const errors = validateForm(formData);
    setValidationErrors(errors);

    if (errors.length > 0) {
      return;
    }

    const roundToPrecision = (value: number, precision: number = 2) => {
      return parseFloat(value.toFixed(precision));
    };

    const variables = {
      title: formData.get('title') as string,
      description: formData.get('description') as string,
      address: formData.get('address') as string,
      city: formData.get('city') as string,
      lat: parseFloat(formData.get('lat') as string) ? parseFloat(formData.get('lat') as string) : parseFloat(property?.location.coordinates[0]),
      lng: parseFloat(formData.get('lng') as string) ? parseFloat(formData.get('lng') as string) : parseFloat(property?.location.coordinates[1]),
      bed: parseInt(formData.get('bed') as string),
      bath: parseInt(formData.get('bath') as string),
      sqFootage: parseInt(formData.get('sqFootage') as string),
      agreementPrice: parseInt(formData.get('agreementPrice') as string) * 100,
      transactionFee: parseInt(formData.get('transactionFee') as string) * 100,
      serviceCharges: parseInt(formData.get('serviceCharges') as string) * 100,
      fundingDeadlineDate: new Date(formData.get('fundingDeadlineDate') as string),
      status: formData.get('status') as string,
      type: formData.get('type') as string,
      excerpt: formData.get('excerpt') as string,
      minimumInvestment: parseInt(formData.get('minimumInvestment') as string),
      manualFunded: manualFunded !== null ? roundToPrecision(parseFloat(manualFunded?.toString()), 2) : null,
    };

    if (mode === 'edit') {

      if (variables.status === 'Active') {
        if ((property?.images?.length ?? 0) < 3) {
          errors.push('Please add at least 3 images.');
          return;
        }
        if ((property?.financials?.length ?? 0) < 1) {
          errors.push('Please add at least 1 financials.');
          return;
        }
        if ((property?.rents?.length ?? 0) < 1) {
          errors.push('Please add at least 1 rent.');
          return;
        }
        setValidationErrors(errors);

        if (errors.length > 0) {
          return;
        }
      }

      updateProperty({
        variables: {
          id: property?.id,
          ...variables,
        },
      });
    } else {
      addProperty({ variables });
    }
  }

  return (
    <>
      <Sidebar />

      <section className='w-full content'>
        <Headline className="flex gap-lg">
          {mode === 'add' ? 'Add Property' : 'Update Property'}
          {loading && <LoadingSpinner size={32} />}
        </Headline>

        <div className="flex flex-col md:flex-row gap">
          <Form className="flex-3" onSubmit={submit} disabled={submitting || updating}>
            <>
              <PropertyFormMessages
                submitting={submitting}
                updating={updating}
                added={added}
                updated={updated}
                updateError={updateError}
                submitError={submitError}
              />
              <PropertyFormProfileSection property={property} />
              <PropertyFormAreaSection property={property} />
              <PropertyFormAgreementSection
                property={property}
                manualFunded={manualFunded}
                setManualFunded={setManualFunded}
              />
              <PropertyFormStatusSection property={property} mode={mode} />
              {validationErrors.length > 0 && (
                <div className="error">
                  <ul>
                    {validationErrors.map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              )}
              <PropertyFormActions mode={mode} disabled={submitting || updating} disabledText={'Saving...'} />
            </>
          </Form>

          <div className="flex flex-col flex-1 gap">
            <PropertyVideosSection property={property} refresh={refresh} mode={mode} />
            <PropertyImagesSection property={property} refresh={refresh} mode={mode} />
            <PropertyAmenitiesSection property={property} refresh={refresh} mode={mode} />
            <PropertyFinancialSection property={property} refresh={refresh} mode={mode} />
            <PropertyTimelineSection property={property} refresh={refresh} mode={mode} />
            <PropertyRentSection property={property} refresh={refresh} mode={mode} />
            <PropertyDocumentsSection property={property} refresh={refresh} mode={mode} />
          </div>
        </div>
      </section>
    </>
  );
}
