import { useEffect, useState } from "react";
import { Property } from "../../../__generated__/graphql";
import { FormButton } from "../../../components/form/button";
import { getPropertyDocumentUrl } from "../../../utils/document.utils";
import { PropertyDocumentsModal } from "./doc-modal";
import ImgPdf from '../../../assets/img/common/pdf.png';

interface Props {
  property?: Property
  mode: 'add' | 'edit';
  refresh?: () => void;
}

export function PropertyDocumentsSection({ property, mode, refresh }: Props) {

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      refresh();
    }
  }, [showModal]);

  return (
    <>
      <PropertyDocumentsModal property={property} show={showModal} onShowHide={setShowModal} />

      <div className="p-4 bg-white border rounded-lg shadow">
        <h2 className="mb-2 text-lg font-semibold">Documents { property && property.documents && property.documents?.length > 0 ? `(${property.documents.length})` : ''}</h2>
        { property && property.documents && property.documents.length > 0 && (
          <div className="flex flex-wrap gap-2 mb-4 overflow-auto max-h-80">
            {property.documents.map((document, index) => (
              <div key={index} className="relative">
                <img src={ImgPdf} alt="Property" className="object-cover rounded-lg " /> <p className="text-sm">{document.filename}</p>
              </div>
            ))}
          </div>
        )}
        { property && property.documents?.length === 0 && <p className="mb-4 text-sm">No documents added</p>}
        {mode === 'add' && <p className="mb-4 text-sm text-slate-400">Property documents can be added after the property is created</p>}
        <FormButton title="Manage Documents" size="sm" disabled={mode === 'add'} onClick={() => setShowModal(true)} />
      </div>
    </>
  );
}